import React from "react";
import styled, { css } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NavBar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import Product from "./components/product";
import Investigation from "./components/investigation";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Info from "./components/info";

import myFontURL from "./assets/font/font.ttf";

export const fontFaces = css`
  @font-face {
    font-family: "OurFont";
    src: url(${myFontURL}) format("truetype");

    font-style: normal;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "OurFont", sans-serif;
`;
const Page = styled.div`
  display: flex;
  height: 100%;
`;

function App() {
  return (
    <Router>
      <MainContainer>
        <NavBar />
        <Page>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/sobre-nosotros" component={About} />
            <Route exact path="/producto" component={Product} />
            <Route exact path="/informacion" component={Investigation} />
            <Route exact path="/contacto" component={Contact} />
            <Route exact path="/info" component={Info} />
          </Switch>
        </Page>
        <Footer />
      </MainContainer>
    </Router>
  );
}

export default App;
