import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { fadeInDown, fadeIn } from "react-animations";
//assets
import Logo from "../assets/logo.jpg";

const FadeDownAnimation = keyframes`${fadeInDown}`;
const FadeInAnimation = keyframes`${fadeIn}`;

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  animation: 2s ${FadeDownAnimation};
  @media (max-width: 1100px) {
    position: fixed;
    animation: 1s ${FadeInAnimation};
    z-index: 100;
    width: 100%;
  }
`;

const NavBarCont = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 90px;
  background-color: #ab8b55;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1100px) {
    height: 100px;
  }
`;
const MenuButtonCont = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ab8b55;
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5%;
`;

const MenuIcon = styled(GiHamburgerMenu)`
  color: white;
  font-size: 30px;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
  }
`;
const CloseMenuIcon = styled(IoMdClose)`
  color: white;
  font-size: 30px;
`;

const MenuText = styled.div`
  color: white;
  font-size: 15px;
`;
const LogoCont = styled.div`
  display: flex;
  width: 300px;
  height: 80%;
  margin-right: 5%;
`;

const MenuCont = styled.div`
  display: flex;
  background-color: #ab8b55;
  height: 357px;
  width: 250px;
  justify-content: flex-end;
  position: absolute;
  margin-top: 80px;
  z-index: 1;
  animation: 1s ${FadeDownAnimation};
  @media (max-width: 1100px) {
    margin-top: 100px;
    width: 100%;
    height: calc(100vh - 100px);
  }
`;

const MenuItemsCont = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  margin-top: 10px;
  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid white;
  }
`;

const MenuItem = styled(NavLink)`
  font-size: 15px;
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid white;

  @media (max-width: 1100px) {
    padding: 0;
    font-size: 35px;
    width: 100%;
    text-align: center;
    align-self: center;
    border-bottom: none;
  }
`;

const LogoImg = styled.img`
  width: 100%;
`;

const NavBar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <MainCont>
      <NavBarCont>
        <MenuButtonCont
          onClick={() => {
            setMenuActive(!menuActive);
          }}
        >
          {menuActive ? <CloseMenuIcon /> : <MenuIcon />}
          <MenuText>MENU</MenuText>
        </MenuButtonCont>
        <LogoCont>
          <LogoImg src={Logo} alt="" />
        </LogoCont>
      </NavBarCont>

      {menuActive ? (
        <MenuCont>
          <MenuItemsCont>
            <MenuItem
              to="/"
              onClick={() => {
                handleScroll();
                setMenuActive(!menuActive);
              }}
            >
              INICIO
            </MenuItem>
            <MenuItem
              to="/sobre-nosotros"
              onClick={() => {
                handleScroll();
                setMenuActive(!menuActive);
              }}
            >
              NOSOTROS{" "}
            </MenuItem>
            <MenuItem
              to="/producto"
              onClick={() => {
                handleScroll();
                setMenuActive(!menuActive);
              }}
            >
              PRODUCTOS
            </MenuItem>
            <MenuItem
              to="/informacion"
              onClick={() => {
                handleScroll();
                setMenuActive(!menuActive);
              }}
            >
              INFORMACIÓN
            </MenuItem>
            <MenuItem
              to="/contacto"
              onClick={() => {
                handleScroll();
                setMenuActive(!menuActive);
              }}
            >
              CONTACTO
            </MenuItem>
          </MenuItemsCont>
        </MenuCont>
      ) : null}
    </MainCont>
  );
};

export default NavBar;
