import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useForm } from "react-hook-form";
//assets
import myFontURL from "../assets/font/font.ttf";
//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ededed;
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
`;

const TopCont = styled.div`
  display: flex;
  /* flex-direction: column; */

  align-self: center;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
  }
`;

const TopLeft = styled.div`
  display: flex;
  width: 23%;
  min-width: 300px;
  flex-direction: column;
  justify-content: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 60%;
  /* border: 2px solid #a3a3a3; */
  /* border-radius: 20px; */
  background-color: #ededed;
  height: 400px;
  min-width: 325px;
  @media (max-width: 1100px) {
    text-align: center;
    width: 90%;
  }
`;
const FormCont = styled.form`
  display: flex;
  flex-direction: column;
  margin: 5%;
  align-items: center;
`;

const JumboCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const JumboTitle = styled.div`
  font-size: 80px;
  font-family: "Varela Round", sans-serif;
  color: white;
`;

const LeftTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  @media (max-width: 1100px) {
    text-align: center;
  }
  margin-bottom: 25px;
`;
const RightTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 1100px) {
    text-align: center;
  }
  margin-bottom: 25px;
`;
const LeftText = styled.div`
  margin-bottom: 25px;
  @media (max-width: 1100px) {
    text-align: center;
  }
`;

const TopInputCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 87%;
  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
`;
const MiddleInputCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const InfoInput = styled.input`
  display: flex;
  height: 35px;
  width: 45%;
  border: 2px solid grey;
  ::-webkit-input-placeholder {
    font-size: 20px;
    line-height: 3;
  }
  font-size: 20px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: black;
  background: transparent;
  :focus {
    border-color: #ab8b55;
  }
  @media (max-width: 1100px) {
    width: 87%;
    margin-bottom: 15px;
    align-self: center;
  }
`;
const EmailInput = styled.input`
  display: flex;
  height: 35px;
  width: 87%;
  border: 2px solid grey;
  margin-top: 20px;

  font-size: 25px;

  ::-webkit-input-placeholder {
    font-size: 20px;
    line-height: 3;
  }
  outline: 0;
  border-width: 0 0 2px;
  border-color: black;
  background: transparent;
  :focus {
    border-color: #ab8b55;
  }
  font-size: 20px;
  @media (max-width: 1100px) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

const MessageInput = styled.textarea`
  display: flex;
  height: 150px;
  width: 87%;
  margin-top: 20px;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid black;
  resize: none;
  background: transparent;
  :focus {
    border-color: #ab8b55;
  }
  /* font-family: Sans-serif; */
  /* border: 2px soli grey; */

  ::-webkit-input-placeholder {
    font-size: 20px;
    /* line-height: 3; */
  }
  font-size: 18px;
  font-family: "OurFont", sans-serif;
  @media (max-width: 1100px) {
    margin-top: 0px;
    /* margin-bottom: 15px; */
  }
`;
const JumboImg = styled.img`
  height: 200px;
  @media (max-width: 1100px) {
    height: 150px;
    width: 100%;
  }
`;

const SendButton = styled.button`
  display: flex;
  border: 2px solid grey;
  padding: 10px 0px 10px 0px;
  min-width: 200px;
  width: 40%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: grey;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin: 10px auto 10px auto;
  background-color: white;
  color: #2e2e2e;
  @media (max-width: 1100px) {
    display: flex;
  }
`;

const Contact = () => {
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    axios({
      method: "POST",
      url: "https://formspree.io/xpzykrgd",
      data: data,
    })
      .then((res) => {
        reset();
      })
      .catch((err) => console.log(err.data));
  };
  // .post("https://formspree.io/marguel.gtz@gmail.com", data)
  console.log(errors);

  return (
    <MainCont>
      {/* <Carousel showThumbs={false} showStatus={false}>
        <div>
          <JumboCont>
            <JumboTitle>CONTACTO</JumboTitle>
          </JumboCont>
          <JumboImg src={Jumbo} alt="na" />
        </div>
      </Carousel> */}
      <TopCont>
        <TopLeft>
          <LeftTitle>CONTACTO</LeftTitle>
          <LeftText>
            DHD Mexico SA de CV
            <br />
            <br />
            - Carretera 57 km 24+800 <br />
            - Monclova Coahuila
            <br />- 25733
          </LeftText>
          <LeftText>
            Telefono: +52 (866) 205-6083 <br />
            E-Mail: ventas@dhdmexico.com
            <br />
          </LeftText>
        </TopLeft>
        <ContactForm>
          <RightTitle>Mandanos un Mensaje.</RightTitle>
          <FormCont onSubmit={handleSubmit(onSubmit)}>
            <TopInputCont>
              <InfoInput
                placeholder="Nombre"
                name="name"
                ref={register({ required: true, maxLength: 80 })}
              />
              <InfoInput
                placeholder="Telefono"
                name="phone"
                ref={register({ required: true, minLength: 6, maxLength: 12 })}
              />
            </TopInputCont>
            <MiddleInputCont>
              <EmailInput
                placeholder="Email"
                name="email"
                ref={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Email no valido",
                  },
                })}
              />
            </MiddleInputCont>

            <MessageInput
              placeholder="Escribe tu Mensaje"
              name="message"
              rows={5}
              cols={5}
              ref={register({ required: true })}
            ></MessageInput>
            <SendButton>Enviar</SendButton>
          </FormCont>
        </ContactForm>
      </TopCont>
    </MainCont>
  );
};

export default Contact;
