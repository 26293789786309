import React, { useState } from "react";
import styled from "styled-components";

const MainCont = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const TopCont = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 80%;
  @media (max-width: 1100px) {
    color: white;
    font-size: 13px;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const TabCont = styled.div`
  /* border: 1px solid red; */
  width: 33.33%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  @media (max-width: 1100px) {
    padding: 10px 0 10px 0;
    justify-content: space-around;
  }
  /* remove first left border */
  &:nth-child(1) {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: none;
    @media (max-width: 1100px) {
      border-left: none;
    }
  }
  &:nth-child(4) {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 1100px) {
      border-right: none;
    }
  }
  &:hover {
    @media (max-width: 1100px) {
      background-color: transparent;
    }
  }
`;

const Selected = styled.div`
  display: flex;
  height: 90%;
  border-bottom: ${(props) => (props.active ? "3px solid black" : "none")};
  width: 70%;
  position: absolute;
  align-self: center;
  @media (max-width: 1100px) {
    border-bottom: ${(props) => (props.active ? "3px solid white" : "none")};
    width: 80%;
  }
`;

const TabComponent = () => {
  const [tabs, setTabs] = useState([
    {
      title: "AHAF-99",
      active: true,
    },
    {
      title: "LEOA+AHAF",
      active: false,
    },
    {
      title: "Producto 3",
      active: false,
    },
  ]);

  const handleTab = (clickedI) => {
    setTabs(
      tabs.map((tab, i) =>
        i === clickedI ? { ...tab, active: true } : { ...tab, active: false }
      )
    );
  };
  return (
    <MainCont>
      <TopCont>
        {tabs.map((tab, i) => (
          <TabCont onClick={() => handleTab(i)}>
            <Tab>{tab.title}</Tab>
            <Selected active={tab.active} />
          </TabCont>
        ))}
      </TopCont>
    </MainCont>
  );
};

export default TabComponent;

// 2 tabs components 1 for inv other for products
