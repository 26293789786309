import React from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//assets
// import Jumbo from "../assets/homeJumbo.jpg";
// import JumboVideo from "../assets/homejumbo.mp4";
// import Zoom from "../assets/homeZoom.jpg";
import infoGraphic from "../assets/infoGraphic.png";
import HomeCenter from "../assets/homeCenter.jpg";
import Hands from "../assets/hands.jpeg";

import homeJumbo from "../assets/homeJumbo.mp4";

import Manzanas from "../assets/manzanas.jpg";

//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
`;

const TopCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const TopTextCont = styled.div``;
const TopTitle = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopTitle2 = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 35px;
  margin-top: 20px;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopText = styled.p`
  font-size: 23px;
  color: gray;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopTextGreen = styled.div`
  margin-bottom: 20px;
  width: 100%;
  font-size: 30px;
  text-align: center;
  color: #2f6932;
  font-weight: 550;
`;

const TopContButton = styled(NavLink)`
  display: flex;
  border: 2px solid grey;
  padding: 10px 0px 10px 0px;
  width: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: grey;
  font-weight: 600;
  margin-top: 25px;
  cursor: pointer;
  text-decoration: none;
`;
const CenterCont = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 450px;
  background-image: url(${HomeCenter});
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    padding: none;
    min-height: 0px;
    /* height: 100%; */
    background-image: none;
    align-items: none;
    justify-content: none;
  }
`;
const CenterTitleCont = styled.div`
  display: flex;
  height: 120px;
  justify-content: center;
  align-items: center;
`;

const CenterCardsCont = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: auto 0;
  @media (max-width: 1100px) {
    justify-content: none;
    align-content: center;
    width: 100%;
    display: none;
  }
`;
const CenterCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  height: 300px;
  width: 20%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
  border: 2px solid black;
  min-width: 250px;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
  }
  @media (max-width: 1100px) {
    margin: 0 auto;
    width: 45%;
    min-width: 100px;
  }
`;

const CardTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-top: 10px;
`;
const CardText = styled.div`
  font-size: 18px;
  margin-top: 10px;
  width: 90%;
  text-align: center;
  color: gray;
  margin-bottom: 30px;
`;
const CardButton = styled(NavLink)`
  display: flex;
  border: 2px solid grey;
  padding: 10px 0px 10px 0px;
  width: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: grey;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
`;
const BottomCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 50px 0;

  @media (max-width: 1100px) {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
const BottomImg = styled.div`
  width: 50%;
  margin-bottom: 30px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
const BottomButton = styled(NavLink)`
  display: flex;
  border: 2px solid #38581f;
  padding: 10px 0px 10px 0px;
  width: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: #38581f;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin: 0 auto;
`;

const Img = styled.img`
  width: 100%;
  border-radius: 40px;
  border: 1px solid gray;
`;

const JumboText = styled.div`
  position: absolute;
  /* z-index: 100; */
  /* font-size: 90px; */
  font-size: 80px;
  font-family: "OurFont", sans-serif;
  color: white;
  font-weight: 500;
  text-align: start;
  bottom: 0;
  margin-bottom: 30px;
  margin-left: 20px;
  /* text-align: center; */
  /* -webkit-text-stroke: 1px black; */
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 42px;
    margin-left: 0px;
    -webkit-text-stroke: 1px black;
    bottom: !important;
  }
`;

const BottomTextLeft = styled.div`
  display: flex;
  width: 59%;
  flex-direction: column;
  min-width: 325px;
`;

const TopImgCont = styled.div`
  width: 100%;
`;
const BottomTextImg = styled.img`
  width: 100%;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.3);
  }

  @media (max-width: 1100px) {
    :hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.2);
    }
  }
`;

const BottomTextRigth = styled.div`
  display: flex;
  position: relative;
  align-content: center;
  justify-content: center;
  width: 40%;
  /* padding-top: 70px; */
  min-width: 325px;
  /* border: 1px solid yellow; */
`;
const BottomTextCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 80%;
  margin-top: 50px;
  /* margin-top: 20px; */

  margin-bottom: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;

const TopTextSide = styled.div`
  font-size: 20px;
  color: gray;
  width: 90%;
  margin-bottom: 35px;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;

const JumboImg = styled.img`
  display: none;
  @media (max-width: 1100px) {
    display: flex;
    height: 190px;
  }
`;

const AccordionCont = styled.div`
  display: none;
  flex-direction: column;
  @media (max-width: 1100px) {
    display: flex;
    /* height: 100%; */
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlayerCont = styled.div`
  /* display: block; */
  height: 400px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1100px) {
    /* display: none; */
    height: 200px;
  }
  /* padding-bottom: 56.25%; */
`;

const JumboPlayer = styled.video`
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
`;
const DeskJumbo = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    /* height: 100%; */
  }
`;

const cardsContent = [
  {
    title: "Sobre Nosotros",
    subTitle: "",
    text:
      "Nos especializamos en la investigación, desarrollo y producción industrial de sustancias húmicas.",
    link: "/sobre-nosotros",
  },
  {
    title: "Productos",
    subTitle: "Justo lo que necesitas",
    text:
      "Nuestros productos se desarrollan y se producen para mejorar su negocio de manera sostenible al fortalecer suelos y plantas",
    link: "/producto",
  },
  {
    title: "Investigacion",
    subTitle: "Amplia gama de aplicaciones",
    text:
      "Trabajamos con investigadores e instituciones académicas para  brindar sustancias húmicas de primer grado",
    link: "/investigacion",
  },
  {
    title: "Contacto",
    subTitle: "Amplia gama de aplicaciones",
    text: "Encuentra nuestros datos de contacto",
    link: "/contacto",
  },
];

const zoomOut = keyframes`
  from {
    /* transition: transform 1s, filter 1s ease-in-out; */
  filter: blur(.5px);
  transform: scale(4.5);
  }

 

  to {
    filter: blur(0);
  transform: scale(4);
  }
  to {
    filter: blur(0);
  transform: scale(1);
  }
`;
const JumboEffect = styled.img`
  animation: 10s ${zoomOut} ease-out;
  animation-delay: 0.5s;
`;

const TopRigth = styled.div`
  width: 40%;
  display: flex;
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
  }
`;

const TopImg = styled.img`
  width: 100%;
  min-width: 325px;
  height: 100% !important;
  border-radius: 5%;
  @media (max-width: 1100px) {
    width: 75%;
    align-self: center;
  }
`;
const TopBottom = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-self: center;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;
const Home = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <MainCont>
      <Carousel showThumbs={false} showStatus={false} showIndicators={false}>
        <div>
          {/* <DeskJumbo>
            <JumboText>SOLUCIONES SUSTENTABLES DE LA TIERRA</JumboText>
            <JumboImg src={Jumbo} alt="na" />
            <PlayerCont>
              <JumboEffect src={Zoom} alt="na" />
              <JumboText>SOLUCIONES SUSTENTABLES DE LA TIERRA</JumboText>
            </PlayerCont>
          </DeskJumbo> */}
          <PlayerCont>
            <JumboPlayer
              // muted
              preload
              autoPlay
              loop
              src={`${homeJumbo}#t=3`}
              type="video/mp4"
            />

            <JumboText>SOLUCIONES SUSTENTABLES DE LA TIERRA</JumboText>
          </PlayerCont>
        </div>
      </Carousel>
      <TopCont>
        <TopTextCont>
          <TopTextGreen>
            Ofrecemos productos de alta calidad a base de Sustancias Húmicas de
            Leonardita que son vitales para el futuro de la agricultura.
          </TopTextGreen>
        </TopTextCont>
        <TopBottom>
          <BottomTextLeft>
            <TopTitle>SOMOS PARTE DE LA SOLUCIÓN</TopTitle>
            <TopText>
              Nuestros productos apoyan la transformación hacia un futuro
              sustentable. Las sustancias húmicas son los componentes mas
              importantes de la materia orgánica natural por lo que dan
              resultados eficientes, eco-friendly y seguros para todos los
              organismos vivos en cualquier ecosistema.
            </TopText>
          </BottomTextLeft>
          <TopRigth>
            {/* <TopImgCont> */}
            <TopImg src={Hands} alt="" />
            {/* </TopImgCont> */}
          </TopRigth>
        </TopBottom>
      </TopCont>
      <CenterCont>
        <AccordionCont>
          {cardsContent.map((content) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CardTitle>{content.title}</CardTitle>
              </AccordionSummary>
              <StyledAccordionDetails>
                <Typography>{content.text}</Typography>
                <CardButton onClick={() => handleScroll()} to={content.link}>
                  Leer Mas
                </CardButton>
              </StyledAccordionDetails>
            </Accordion>
          ))}
        </AccordionCont>
        <CenterCardsCont>
          {cardsContent.map((content) => (
            <CenterCard>
              <CardTitle>{content.title}</CardTitle>
              <CardText>{content.text}</CardText>
              {/* a donde lleva este boton */}
              <CardButton to={content.link} onClick={() => handleScroll()}>
                Leer Mas
              </CardButton>
            </CenterCard>
          ))}
        </CenterCardsCont>
      </CenterCont>
      <BottomTextCont>
        <BottomTextLeft>
          <TopTitle2>Incrementa la Fertilidad de tus Suelos </TopTitle2>
          <TopTextSide>
            Los ácidos húmicos se encuentran de forma natural en suelos sanos,
            pero hoy día hacen falta de manera implícita debido al intenso
            desgaste en las tierras cultivables del mundo. En DHD MEXICO
            obtenemos nuestros ácidos húmicos de la Leonardita, una forma de
            lignito muy oxidada.
          </TopTextSide>
          <BottomButton onClick={() => handleScroll()} to="/informacion">
            Conoce mas...
          </BottomButton>
        </BottomTextLeft>
        <BottomTextRigth>
          <TopImgCont>
            {/*  foto mas grande */}
            <BottomTextImg src={Manzanas} alt="" />
          </TopImgCont>
        </BottomTextRigth>
      </BottomTextCont>

      <BottomCont>
        <BottomImg>
          <Img src={infoGraphic} alt="" />
        </BottomImg>
        <BottomButton onClick={() => handleScroll()} to="/producto">
          Nuestro Producto
        </BottomButton>
      </BottomCont>
    </MainCont>
  );
};

export default Home;
