import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//assets
import Jumbo from "../assets/aboutJumbo.jpg";
import Value1 from "../assets/value1.jpg";
import Value2 from "../assets/value2.jpg";
import Value3 from "../assets/value3.jpg";
import Value4 from "../assets/value4.jpg";
import aboutTop from "../assets/aboutTop.jpg";
import Goals from "../assets/onuGoals.jpg";

//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
`;

const TopCont = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-self: center;
  width: 80%;
  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;
const MiddleCont = styled.div`
  display: flex;
  align-self: center;
  width: 85%;
  margin-top: 25px;
  margin-bottom: 100px;
  justify-content: center;
  /* flex-direction: column; */
`;

const TopLeft = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  min-width: 325px;
`;
const TopTitle = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopText = styled.p`
  font-size: 23px;
  color: gray;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const MiddleText = styled.p`
  font-size: 25px;
  color: black;
  width: 90%;
  font-weight: 400;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 22px;
    width: 100%;
  }
`;
const TopRigth = styled.div`
  width: 40%;
  display: flex;
  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
  }
`;

const TopImgCont = styled.div`
  width: 100%;
  display: flex;
`;
const TopImg = styled.img`
  width: 100%;
  min-width: 325px;
  height: 100% !important;
  @media (max-width: 1100px) {
    width: 75%;
    align-self: center;
  }
`;
const BottomCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
const BottomTitle = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
`;
const ValuesCont = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  @media (max-width: 1100px) {
    width: 95%;
  }
`;
const ValueCont = styled.div`
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
`;
const ValueText = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;
const ValueImg = styled.img`
  width: 65%;
  /* height: 100%; */
`;

const JumboCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const JumboTitle = styled.div`
  font-size: 70px;
  /* font-family: "Varela Round", sans-serif; */
  position: absolute;
  /* z-index: 100; */
  color: white;
  /* font-weight: 550; */
  /* text-align: start; */
  /* bottom: 0; */
  margin-bottom: 30px;
  margin-left: 20px;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 42px;
    margin-left: 0;
    /* -webkit-text-stroke: 1px black; */
    /* bottom: !important; */
  }
`;

const JumboImg = styled.img`
  @media (max-width: 1100px) {
    height: 190px;
    width: 100%;
  }
`;

const GoalImg = styled.img`
  display: flex;
  width: 70%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
  height: 100% !important;

  @media (max-width: 1100px) {
    width: 95%;
  }
`;

const OnuCont = styled.div`
  display: flex;
  align-self: center;
  width: 85%;
  margin-top: 25px;
  padding-bottom: 80px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const Home = () => {
  return (
    <MainCont>
      <Carousel showThumbs={false} showStatus={false} showIndicators={false}>
        <div>
          <JumboCont>
            <JumboTitle>SOBRE NOSOTROS</JumboTitle>
          </JumboCont>
          <JumboImg src={Jumbo} alt="na" />
        </div>
      </Carousel>
      {/* <MiddleCont>
        <MiddleText>
          Nos especializamos en el desarrollo y producción de productos a base
          de sustancias húmicas de leonardita. Nuestro principal objetivo es
          brindar bajo un perfil innovador y de alta calidad productos que
          brinden resultados de alto impacto a nuestros clientes y al planeta.
        </MiddleText>
      </MiddleCont> */}
      <TopCont>
        <TopLeft>
          <TopTitle>Quienes somos</TopTitle>
          <TopText>
            {/* Nuestros productos apoyan la transformación hacia un futuro
            sustentable. Las sustancias húmicas son los componentes mas
            importantes de la materia orgánica natural por lo que dan resultados
            eficientes, eco-friendly y seguros para todos los organismos vivos
            en cualquier ecosistema. */}
            Nos especializamos en el desarrollo y producción de productos a base
            de sustancias húmicas de leonardita. Nuestro principal objetivo es
            brindar bajo un perfil innovador y de alta calidad productos que
            brinden resultados de alto impacto a nuestros clientes y al planeta.
          </TopText>
        </TopLeft>
        <TopRigth>
          {/* <TopImgCont> */}
          <TopImg src={aboutTop} alt="" />
          {/* </TopImgCont> */}
        </TopRigth>
      </TopCont>

      <BottomCont>
        <BottomTitle>Nuestros Valores</BottomTitle>
        <ValuesCont>
          <ValueCont>
            <ValueImg src={Value1} alt="" />
            <ValueText>Integridad</ValueText>
          </ValueCont>
          <ValueCont>
            <ValueImg src={Value2} alt="" />
            <ValueText>Calidad</ValueText>
          </ValueCont>
          <ValueCont>
            <ValueImg src={Value3} alt="" />
            <ValueText>Compromiso</ValueText>
          </ValueCont>
          <ValueCont>
            <ValueImg src={Value4} alt="" />
            <ValueText>Responsabilidad</ValueText>
          </ValueCont>
        </ValuesCont>
      </BottomCont>
      <OnuCont>
        <TopTitle>
          Apoyamos los Objectivos de Desarrollo Sostenible de la ONU
        </TopTitle>
        <GoalImg src={Goals} alt="" />
      </OnuCont>
    </MainCont>
  );
};

export default Home;
