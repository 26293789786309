import React from "react";
import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css";

//assets
import Info1 from "../assets/info1.jpeg";
//foto de mano es jpeg
import Mano from "../assets/mano.jpg";
import Grafica from "../assets/grafica.png";
import Graph from "../assets/graph.jpg";
import Mina1 from "../assets/mina1.jpeg";
import Mina2 from "../assets/mina2.jpeg";
import Proceso from "../assets/proceso.png";

//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopCont = styled.div`
  display: flex;

  align-self: center;
  width: 80%;
  /* margin-top: 20px; */
  margin-bottom: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;
const MiddleCont = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f4f4f4;
  padding-bottom: 50px;
  padding-top: 40px;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;

const MiddleContent = styled.div`
  display: flex;
  width: 80%;
  background-color: #f4f4f4;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    width: 100%;
    /* align-items: center; */
  }
`;

const TopLeft = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  min-width: 325px;
`;
const MiddleRight = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  min-width: 325px;
  margin: 0 auto;
`;
const TopTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  margin-top: 20px;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopText = styled.div`
  font-size: 20px;
  color: gray;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopRigth = styled.div`
  width: 40%;
  padding-top: 70px;
  min-width: 325px;
`;
const MiddleLeft = styled.div`
  display: flex;
  width: 45%;
  padding-top: 70px;
  min-width: 325px;
  @media (max-width: 1100px) {
    width: 75%;
    align-self: center;
    padding-top: 0px;
    margin: 0 auto;
  }
`;
const TopImgCont = styled.div`
  width: 100%;
`;
const TopImg = styled.img`
  width: 100%;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.3);
  }

  @media (max-width: 1100px) {
    :hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.2);
    }
  }
`;
const MiddleImg = styled.img`
  width: 100%;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
  }

  @media (max-width: 1100px) {
    :hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.2);
    }
  }
`;
const BottomCont = styled.div`
  display: flex;
  align-self: center;
  width: 85%;
  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: center;
`;
const BottomText = styled.p`
  font-size: 25px;
  color: black;
  width: 90%;
  font-weight: 400;
  text-align: center;
`;

const GraphCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
`;
const GraphImgCont = styled.div`
  width: 70%;
  margin-bottom: 30px;
  @media (max-width: 1100px) {
    width: 95%;
    align-self: center;
  }
`;
const GraficaImgCont = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
  @media (max-width: 1100px) {
    width: 95%;
    align-self: center;
  }
`;
const GraphImg = styled.img`
  width: 100%;
`;
const GraphBottomText = styled.div`
  font-size: 25px;
  color: gray;
  width: 70%;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 1100px) {
    font-size: 20px;
    width: 90%;
  }
`;

//Minas section styles
const ImgMatrix = styled.div`
  display: flex;
  flex-direction: column;
`;
const GraphImgsCont = styled.div`
  display: flex;
  margin: 30px auto 0 auto;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    width: 95%;
    align-self: center;
  }
`;
const MinasImgCont = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  padding-bottom: 30px;
  @media (max-width: 1100px) {
    width: 95%;
    align-self: center;
  }
`;
const MinasLeftImgCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 350px;
`;
const MinasRightImgCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 350px;
`;
const GraphLeftCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 350px;
  margin-bottom: 30px;
`;
const GraphRightCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 350px;
`;
const ImgSub = styled.div`
  display: flex;
  font-size: 17px;
  font-weight: bold;
  color: gray;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 1100px) {
    font-size: 20px;
    width: 100%;
  }
`;

const MatrixImg = styled.img`
  width: 100%;
  margin: auto 0;
`;
const MatrixTitle = styled.div`
  text-align: center;
  font-size: 45px;
  font-weight: 500;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #f4f4f4;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;

const Note = styled.div`
  font-size: 15px;
  color: gray;
  width: 100%;
`;

const Info = () => {
  return (
    <MainCont>
      <TopCont>
        <TopLeft>
          <TopTitle>¿Qué son los ácidos húmicos ?</TopTitle>
          <TopText>
            <br />
            Provienen de la <b>Materia húmica</b> que se forma a través de la
            humificación biológica de la materia vegetal, animal y a través de
            las actividades biológicas de microorganismos. Los ácidos húmicos
            provienen de varias fuentes,{" "}
            <b>nosotros utilizamos leonardita de alta concentración.</b>
            <br />
            <br />
            La <b>leonardita</b> es una materia orgánica proveniente de los
            mantos de carbón mineral, es formada por grandes masas forestales
            como árboles, plantas, fauna etc existentes en el ecosistema que
            mediante el paso de millones de años a una presión ejercida en el
            subsuelo sufrieron un proceso de mineralización provocando bancos o
            depósitos de estos minerales en los subsuelos de algunas zonas del
            mundo.
          </TopText>
        </TopLeft>
        <TopRigth>
          <TopImgCont>
            {/*  foto mas grande */}
            <TopImg src={Mano} alt="" />
          </TopImgCont>
        </TopRigth>
      </TopCont>
      <MiddleCont>
        <MiddleContent>
          <MiddleLeft>
            <TopImgCont>
              <MiddleImg src={Info1} alt="" />
            </TopImgCont>
          </MiddleLeft>
          <MiddleRight>
            <TopTitle>Leonardita</TopTitle>
            <TopText>
              <br />
              Debido a su cercanía a la superficie del terreno, la parte
              superior del manto no completó el proceso de transformación a
              carbón (mineralización), esto provocó cambios en los parámetros de
              presión y calor, influyendo en la humedad y oxigenación lo que
              resulto en un proceso de humificación natural transformando estos
              residuos en leonardita, la materia orgánica con mas altos niveles
              de sustancias húmicas. Estas se dividen en ácidos húmicos, ácidos
              Fúlvicos y huminas.
            </TopText>
          </MiddleRight>
        </MiddleContent>
      </MiddleCont>
      {/* PARRAFO ARRIVA DE GRAFICA */}
      {/* <BottomCont>
        <BottomText>
          Nos especializamos en el desarrollo y producción de productos a base
          de sustancias húmicas de leonardita. Nuestro principal objetivo es
          brindar bajo un perfil innovador y de alta calidad productos que
          brinden resultados de alto impacto a nuestros clientes y al planeta.
        </BottomText>
      </BottomCont> */}

      <ImgMatrix>
        <GraphImgsCont>
          <GraphLeftCont>
            <ImgSub>
              Stevenson Sustancias Húmicas (polímeros pigmentados)
            </ImgSub>
            <MatrixImg src={Grafica} />
          </GraphLeftCont>
          <GraphRightCont>
            <ImgSub>
              Existen varias fuentes que producen sustancias húmicas. Nosotros
              utilizamos leonardita de alta concentración.
            </ImgSub>
            <MatrixImg src={Graph} />
          </GraphRightCont>
        </GraphImgsCont>
        <MatrixTitle>Minas de Carbón Mineral</MatrixTitle>
        <MinasImgCont>
          <MinasLeftImgCont>
            <MatrixImg src={Mina1} />
            <ImgSub>Vista Panoramica</ImgSub>
          </MinasLeftImgCont>
          <MinasRightImgCont>
            <MatrixImg src={Mina2} />
            <ImgSub>Vista de Material</ImgSub>
          </MinasRightImgCont>
        </MinasImgCont>
      </ImgMatrix>

      <GraphCont>
        <GraphBottomText>
          En comparación con otros productos orgánicos la Leonardita es muy rica
          en ácidos húmicospor tener un proceso de humificación con una duracion
          mayor a 150 millones de años. Razon por la que tiene propiedades
          extremadamente bioactivas. Esta actividad biológica es aproximadamente
          5 veces más fuerte que otras materias húmicas.
        </GraphBottomText>
        <GraphImgCont>
          <GraphImg src={Proceso} />
          <Note>
            **1kg de Leonardita corresponde aproximadamente a 5 kg de otras
            fuentes orgánicas de ácidos húmicos.
          </Note>
          <Note>
            **1kg de HF-DIRECT99 es equivalente a aproximadamente 30 toneladas
            métricas de composta/estiércol.
          </Note>
        </GraphImgCont>

        <GraphBottomText>
          <b>
            La leonardita actúa como acondicionador para el suelo y como
            bioestimulante para la planta.
          </b>{" "}
          La Leonardita mejora especialmente el crecimiento de las plantas,
          raíces y la fertilidad del suelo. La Leonardita no es un fertilizante.
        </GraphBottomText>
      </GraphCont>
    </MainCont>
  );
};

export default Info;
