import React, { useState } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//assets
import Jumbo from "../assets/investigationJumbo.jpg";
// import Uni from "../assets/uni.jpg";
import testTube from "../assets/roots.jpeg";
import Science from "../assets/invScience.jpeg";
import Info from "./info";

//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
`;

const TopCont = styled.div`
  display: flex;
  margin-top: 30px;
  align-self: center;
  width: 85%;
  /* margin-top: 20px; */
  margin-bottom: 50px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;
const BottomCont = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f4f4f4;
  padding-bottom: 60px;
  padding-top: 40px;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
`;

const BottomContent = styled.div`
  display: flex;
  width: 90%;
  background-color: #f4f4f4;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    width: 100%;
    /* align-items: center; */
  }
`;

const TopLeft = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  min-width: 325px;
  @media (max-width: 1100px) {
    width: 90%;
    /* align-items: center; */
  }
`;
const BottomRight = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  @media (max-width: 1100px) {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;
const TopTitle = styled.div`
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopText = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: gray;
  width: 90%;
  @media (max-width: 1100px) {
    text-align: center;
    width: 100%;
  }
`;
const TopRigth = styled.div`
  width: 40%;
  padding-top: 60px;
  min-width: 325px;
  @media (max-width: 1100px) {
    width: 85%;
    /* align-items: center; */
  }
`;
const BottomLeft = styled.div`
  width: 45%;
  padding-top: 70px;
  min-width: 325px;
  @media (max-width: 1100px) {
    width: 75%;
    align-self: center;
    padding-top: 0px;
    margin: 0 auto;
  }
`;
const TopImgCont = styled.div`
  display: flex;
  justify-content: center;
`;
const TopImg = styled.img`
  position: relative;
  width: 100%;
  border-radius: 15px;

  height: 100% !important;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
    /* right: 40px; */
  }
  @media (max-width: 1100px) {
    width: 80%;
    transform: scale(1);
    :hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.2);
      /* right: 40px; */
    }
  }
`;
const BottomImg = styled.img`
  width: 80%;
  height: 100% !important;
  border-radius: 15px;

  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
  }
`;

const Img = styled.img`
  width: 100%;
`;

const JumboCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const JumboImg = styled.img`
  @media (max-width: 1100px) {
    height: 190px;
    width: 100%;
  }
`;

const JumboTitle = styled.div`
  font-size: 80px;
  font-family: "Varela Round", sans-serif;
  color: white;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 42px;
    /* -webkit-text-stroke: 1px black; */
    bottom: !important;
  }
`;

const DividerCont = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  align-self: center;
`;

const Divider = styled.div`
  display: flex;
  border: 2px solid black;
  height: 0;
  width: 80%;
`;

const UniCont = styled.div`
  width: 30%;
`;

const TabMainCont = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 80%;
  @media (max-width: 1100px) {
    font-size: 13px;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const TabCont = styled.div`
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  @media (max-width: 1100px) {
    padding: 10px 0 10px 0;
    justify-content: space-around;
  }
  /* remove first left border */
  &:nth-child(1) {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: none;
    @media (max-width: 1100px) {
      border-left: none;
    }
  }
  &:nth-child(4) {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 1100px) {
      border-right: none;
    }
  }
  &:hover {
    @media (max-width: 1100px) {
      background-color: transparent;
    }
  }
`;

const Selected = styled.div`
  display: flex;
  height: 90%;
  border-bottom: ${(props) => (props.active ? "3px solid black" : "none")};
  width: 70%;
  position: absolute;
  align-self: center;
  @media (max-width: 1100px) {
    border-bottom: ${(props) => (props.active ? "3px solid black" : "none")};
    width: 80%;
  }
`;

const Investigation = () => {
  const [tabs, setTabs] = useState([
    {
      title: "Informacion",
      active: true,
    },
    {
      title: "Investigacion",
      active: false,
    },
  ]);

  const handleTab = (clickedI) => {
    setTabs(
      tabs.map((tab, i) =>
        i === clickedI ? { ...tab, active: true } : { ...tab, active: false }
      )
    );
  };

  return (
    <MainCont>
      <Carousel showIndicators={false} showThumbs={false} showStatus={false}>
        <div>
          <JumboCont>
            <JumboTitle>
              {tabs[0].active ? "INFORMACIÓN" : "INVESTIGACIÓN"}
            </JumboTitle>
          </JumboCont>
          <JumboImg src={Jumbo} alt="na" />
        </div>
      </Carousel>
      <TabMainCont>
        {tabs.map((tab, i) => (
          <TabCont onClick={() => handleTab(i)}>
            <Tab>{tab.title}</Tab>
            <Selected active={tab.active} />
          </TabCont>
        ))}
      </TabMainCont>
      {tabs[0].active ? (
        <Info />
      ) : (
        <>
          {/* <DividerCont>
          <Divider />
          <UniCont>
            <Img src={Uni} alt="" />
          </UniCont>
        </DividerCont> */}
          <TopCont>
            <TopLeft>
              <TopTitle>Horticultura e invernaderos</TopTitle>
              <TopText>
                Optimizar y regular parámetros ambientales como la luz,
                temperatura y humedad es importante pero también es importante
                un suelo o sustrato que proporcione propiedades físicas,
                químicas y bioquímicas apropiadas. La aplicación de sustancias
                húmicas ayuda a preparar un medio de cultivo con una buena
                porosidad aumentando la capacidad de intercambio catiónico y la
                actividad biológica. Como resultado, el medio de cultivo está
                bien aireado, las raíces pueden penetrar más fácilmente, los
                nutrientes y el agua se almacenan mejor, el PH está protegido
                contra rangos extremos y se reduce la salinidad además aumenta
                la resistencia contra enfermedades. HF-DIRECT99 proporcionan los
                beneficios mencionados anteriormente, para estimular el
                crecimiento de las plantas y obtener un alto rendimiento en
                todos los cultivos hortícolas
              </TopText>
            </TopLeft>
            <TopRigth>
              <TopImgCont>
                <TopImg src={Science} alt="" />
              </TopImgCont>
            </TopRigth>
          </TopCont>
          <BottomCont>
            <BottomContent>
              <BottomLeft>
                <TopImgCont>
                  <BottomImg src={testTube} alt="" />
                </TopImgCont>
              </BottomLeft>
              <BottomRight>
                <TopTitle>Hidroponico</TopTitle>
                <TopText>
                  El cultivo hidropónico se lleva a cabo en un ambiente cerrado,
                  es posible cultivar plantas con un mejor uso de recursos y un
                  mínimo de desperdicio. Los ácidos húmicos en sistemas
                  hidropónicos mejoran el rendimiento de la solución nutritiva.
                  Las sustancias húmicas pueden mejorar la disponibilidad de
                  nutrientes para las plantas en la solución de nutrientes
                  aumentando la fotosíntesis. Por lo tanto, las plantas crecerán
                  y almacenarán más carbohidratos para que puedan usar toda la
                  energía necesaria para crecer rápidamente. Como resultado se
                  tendrá un sistema de raíces bien desarrollado con un mayor
                  volumen de pelos de raíz y raíces alimentadoras esto permitirá
                  a la planta absorber todos los nutrientes necesarios en la
                  solución acelerando la división celular más rápidamente.
                </TopText>
              </BottomRight>
            </BottomContent>
          </BottomCont>
        </>
      )}
    </MainCont>
  );
};

export default Investigation;
