import React, { useState } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Tabs from "./tabComponent";

//assets
import Jumbo from "../assets/productJumbo.jpg";
import productBags from "../assets/productBags.jpg";
import productBenefits from "../assets/productBenefits.jpg";
import product2Benefits from "../assets/product2Benefits.jpg";
import product3Benefits from "../assets/product3Benefits.jpg";
import product1Info from "../assets/productInfo.jpg";
import product2Info from "../assets/product2Info.jpg";
import product3Info from "../assets/product3Info.jpg";
import productTractor from "../assets/productTractor.png";

import prodLogo from "../assets/prodLogo.png";

//styles
const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1100px) {
    margin-top: 100px;
  }
`;

const TopCont = styled.div`
  display: flex;
  /* flex-direction: column; */
  height: 2px solid blue;
  align-self: center;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const TopLeft = styled.div`
  display: flex;
  width: 90%;
  min-width: 325px;
  align-self: center;
  @media (max-width: 1100px) {
    width: 90%;
    margin: 0 auto;
    /* min-height: 370px; */
  }
`;

const TopRigth = styled.div`
  width: 35%;
  min-width: 325px;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 100%;
  /* object-fit: contain; */
  min-width: 325px;
  height: 100%;
`;

const BottomCont = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  justify-content: space-around;
  padding: 30px 30px 30px 30px;
  flex-wrap: wrap;
`;

const BottomLeft = styled.div`
  width: 40%;
  min-width: 325px;
  @media (max-width: 1100px) {
    display: none;
  }
`;
const BottomRight = styled.div`
  width: 50%;
  min-width: 325px;

  @media (max-width: 1100px) {
    min-height: 100px;
  }
`;

const JumboCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

const JumboTitle = styled.div`
  font-size: 80px;
  font-family: "Varela Round", sans-serif;
  color: white;
  @media (max-width: 1100px) {
    text-align: center;
    font-size: 42px;
    /* -webkit-text-stroke: 1px black; */
    bottom: !important;
  }
`;

const TabMainCont = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 80%;
  @media (max-width: 1100px) {
    font-size: 13px;
    padding-left: 3%;
    padding-right: 3%;
  }
`;

const JumboImg = styled.img`
  @media (max-width: 1100px) {
    height: 150px;
    width: 100%;
  }
`;
const ProdImg = styled.img`
  width: 60%;
`;

const TabCont = styled.div`
  /* border: 1px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  @media (max-width: 1100px) {
    padding: 10px 0 10px 0;
    justify-content: space-around;
  }
  /* remove first left border */
  &:nth-child(1) {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-left: none;
    @media (max-width: 1100px) {
      border-left: none;
    }
  }
  &:nth-child(4) {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 1100px) {
      border-right: none;
    }
  }
  &:hover {
    @media (max-width: 1100px) {
      background-color: transparent;
    }
  }
`;

const Selected = styled.div`
  display: flex;
  height: 90%;
  border-bottom: ${(props) => (props.active ? "3px solid black" : "none")};
  width: 70%;
  position: absolute;
  align-self: center;
  @media (max-width: 1100px) {
    border-bottom: ${(props) => (props.active ? "3px solid black" : "none")};
    width: 80%;
  }
`;

const Note = styled.div`
  font-size: 15px;
  color: darkgray;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
`;

const Product = () => {
  const [tabs, setTabs] = useState([
    {
      title: "HF-DIRECT99",
      active: true,
    },
    {
      title: "LEOA+AHAF",
      active: false,
    },
    {
      title: "LEONARDITA",
      active: false,
    },
  ]);

  const handleTab = (clickedI) => {
    setTabs(
      tabs.map((tab, i) =>
        i === clickedI ? { ...tab, active: true } : { ...tab, active: false }
      )
    );
  };

  return (
    <MainCont>
      <Carousel showIndicators={false} showThumbs={false} showStatus={false}>
        <div>
          <JumboCont>
            <JumboTitle>PRODUCTOS</JumboTitle>
          </JumboCont>
          <JumboImg src={Jumbo} alt="na" />
        </div>
      </Carousel>
      <TabMainCont>
        {tabs.map((tab, i) => (
          <TabCont onClick={() => handleTab(i)}>
            <Tab>{i === 0 ? <ProdImg src={prodLogo} /> : tab.title}</Tab>
            <Selected active={tab.active} />
          </TabCont>
        ))}
      </TabMainCont>
      <TopCont>
        <TopLeft>
          {tabs[0].active ? (
            <Img src={product1Info} alt="na" />
          ) : tabs[1].active ? (
            <Img src={product2Info} alt="na" />
          ) : (
            <Img src={product3Info} alt="na" />
          )}
        </TopLeft>
        {/* <TopRigth>
          <Img src={productBags} alt="na" />
        </TopRigth> */}
      </TopCont>
      <BottomCont>
        <BottomLeft>
          <Img src={productTractor} alt="na" />
        </BottomLeft>
        <BottomRight>
          {tabs[0].active ? (
            <>
              <Img src={productBenefits} alt="na" />
              <Note>
                ** Presentaciones: Granel, Súper Sacos y Sacos de 25kg
              </Note>
            </>
          ) : tabs[1].active ? (
            <Img src={product2Benefits} alt="na" />
          ) : (
            <Img src={product3Benefits} alt="na" />
          )}
        </BottomRight>
      </BottomCont>
    </MainCont>
  );
};

export default Product;
