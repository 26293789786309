import React from "react";
import styled from "styled-components";

import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logoFooter.png";

import omriSeal from "../assets/omriSeal.png";

const MainCont = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const TopCont = styled.div`
  display: flex;
  justify-content: center;
  background-color: #2e2e2e;
  /* background-color: #ab8b55; */
  height: 50px;
  align-items: center;
`;

const BottomCont = styled.div`
  display: flex;
  background-color: #222323;
  /* background-color: #ab8b55; */
  flex-wrap: wrap;
  height: 250px;
  align-items: center;
  /* justify-content: space-around; */
  @media (max-width: 1100px) {
    background-color: #2e2e2e;
    height: 100%;
    width: 100%;
  }
`;

const LeftCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: space-between;
  padding-top: 30px;
  min-width: 250px;
  @media (max-width: 1100px) {
    width: 100%;
    display: none;
  }
`;

const LogoCont = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  min-width: 250px;
`;

const LogoImg = styled.img`
  width: 100%;
  height: 100% !important;
`;

const CopyText = styled.div`
  display: flex;
  color: white;
  justify-self: flex-end;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 5px;
  align-self: center;
`;

const RigthCont = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 50px;
  flex-wrap: wrap;
  height: 80%;
  @media (max-width: 1100px) {
    width: 100%;
    margin-left: 0;
    display: none;
  }
`;

const InfoCard = styled.div`
  max-width: 250px;
`;

const CardTitle = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const CardText = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 350;
`;

const Insta = styled(FaInstagram)`
  font-size: 40px;
  color: white;
  cursor: pointer;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
  }
  margin-left: 5px;
`;

const Facebook = styled(FaFacebookSquare)`
  font-size: 40px;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
  }
`;
const Linkedin = styled(FaLinkedin)`
  font-size: 40px;
  cursor: pointer;
  color: white;
  margin-left: 5px;
  :hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
  }
`;
const ContactButton = styled(NavLink)`
  display: none;
  border: 2px solid grey;
  padding: 5px 0px 5px 0px;
  width: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: grey;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin: 10px auto 10px auto;
  background-color: white;
  color: #2e2e2e;
  @media (max-width: 1100px) {
    display: flex;
  }
`;
const OmriSeal = styled.img`
  display: flex;
  align-self: center;
  width: 20%;
  display: flex;
`;

const Footer = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <MainCont>
      <TopCont>
        <Linkedin
          onClick={() => window.open("https://www.linkedin.com/", "_blank")}
        />
        <Insta
          onClick={() =>
            window.open("https://www.instagram.com/dhdmexico/", "_blank")
          }
        />
        <Facebook
          onClick={() =>
            window.open(
              "https://www.facebook.com/DHD-M%C3%A9xico-106649311147545/?modal=admin_todo_tour",
              "_blank"
            )
          }
        />
      </TopCont>
      <BottomCont>
        <LeftCont>
          <LogoCont>
            <LogoImg src={Logo} alt="" />
          </LogoCont>
          <CopyText>Copyright DHD Mexico</CopyText>
        </LeftCont>
        <RigthCont>
          <InfoCard>
            <CardTitle>Soluciones Sustensables de la Tierra</CardTitle>
            <CardText>
              Nos dedicamos a la investigación, desarrollo y producción
              industrial de sustancias húmicas de leonardita para el sector
              agrícola. Con el objetivo de nutrir suelos y plantas de manera
              orgánica y sostenible para el planeta.
            </CardText>
          </InfoCard>
          <InfoCard>
            <CardTitle>Contacto</CardTitle>
            <CardText>
              DHD Mexico SA de CV
              <br />
              <br />
              - Carretera 57 km 24+800 <br />
              - Monclova Coahuila
              <br />- 25733
              <br />
              <br />
              Telefono: (866) 205-6083 <br />
              E-Mail: ventas@dhdmexico.com
            </CardText>
          </InfoCard>

          <OmriSeal src={omriSeal} />
        </RigthCont>
        <ContactButton onClick={() => handleScroll()} to="/contacto">
          Contacto
        </ContactButton>
      </BottomCont>
    </MainCont>
  );
};

export default Footer;
